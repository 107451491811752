import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/blog/blogs-details.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bonding your pets helps provide a fun and safe experience for both your cat and dog, but it can be difficult.`}</p>
    <h2>{`Obedience training for your dog`}</h2>
    <p>{`Unfortunately, most of the time dogs will be the ones who instigate the trouble between your dog and your cat. Most dogs have a higher prey instinct than cats do, which can cause them to chase after small animals -- including cats.`}</p>
    <p>{`Practicing obedience training can help your dog learn to be more patient and less aggressive, which means less instigation and less issues. Additionaly, proper obedience training can help ensure that your dog responds to commands like "No" or "Stop" should anything start to go the wrong way.`}</p>
    <h2>{`Establish a safe spot for your cat`}</h2>
    <p>{`If your cat feels threatened by your dog, it will almost certainly try to hide. If it can't hide, it will try to run away. If it can't run away, it will try to fight back. Help prevent these later two behaviors by establishing an area where your cat can hide and observe your dog.`}</p>
    <p>{`These safe spots are often best at higher-up locations like a book-shelf or window perch. Many companies make wall mountable safe spots for cats that can also help provide exercise for your cat, too!`}</p>
    <h2>{`Desensitization`}</h2>
    <p>{`The ultimate goal is to densensitize your cat to your dog and your dog to your cat. Densensitization takes time, but wont happen if the two are completely separated. A common practice is to seperate your cat and dog with a baby gate. The holes in the gate allow them to smell and see eachother but can help protect them from eachother should one get too excited.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      